import React from "react";
import ProfileStatus from "./profileStatus";

const VaccineStatus = ({ org, user }) => {
  const checkVaccineSubmission = () => {
    return user.vaccine && user.vaccine.date && user.vaccine.date;
  };

  if (!org || org.requireVaccine !== "Yes") return null;

  return (
    <ProfileStatus
      isComplete={checkVaccineSubmission()}
      incompleteText="Submit your vaccine information information"
      completeText={
        "Vaccine information submitted - " +
        (checkVaccineSubmission() && user.vaccine?.reviewed
          ? "accepted"
          : "under review")
      }
      link="/vaccine"
    />
  );
};

export default VaccineStatus;
