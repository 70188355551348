import React, { useState } from "react";

import { CLink } from "../common/customs/customLinks";
import ProfileForm from "./profileForm";
import CustomConfirm from "./../common/customs/customConfirm";
import Checkbox from "./../common/form/checkBox";
import allowables from "../../utils/allowables";
import MiniHeader from "../common/pageComponents/miniHeader";

const ProfileInfo = ({
  user,
  org,
  player,
  data,
  onSubmit,
  onMoveToFreeAgents,
  waiverWire,
  setWaiverWire,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <div>
      <MiniHeader>
        {user.firstName + (user.lastName ? " " + user.lastName : "")}
      </MiniHeader>
      <div className="form-divided-section">
        {user.role?.includes("captain") && (
          <div>
            <CLink
              path={`/teams?${user.teamID ? "info&" : ""}q=${
                user.teamID || "new"
              }`}
              button={true}
              buttonStyle="btn-block btn-info btn-sm"
            >
              {user.teamID ? "Manage" : "Create"} My Team
            </CLink>
            <br />
          </div>
        )}
        {user.orgName && (
          <h4>
            {allowables.ballIcon(allowables.translateSportName(org))}{" "}
            {user.orgName}
          </h4>
        )}
        {user.role && (
          <h4>{allowables.translateRole(user.role, user.sport)}</h4>
        )}
        <h5>{user.email}</h5>
        {player.teamID && (
          <h4>
            {player.teamName !== "Free Agents" ? (
              <CLink path={"/teams?info&q=" + player.teamID._id}>
                {player.teamName}
              </CLink>
            ) : (
              player.teamName
            )}
          </h4>
        )}
        {player.teamName === "Free Agents" && (
          <h6>
            <small>Waiver Wire Expires: </small>
            {player.waiverWire
              ? allowables.shortDate(player.waiverWire.expiration, true)
              : "Open"}
          </h6>
        )}
        <ProfileForm data={data} onSubmit={onSubmit} isLocked={!user.role} />

        {user.playerID &&
          user.role === "player" &&
          user.teamName !== "Free Agents" && (
            <div className="text-right" style={{ marginTop: 30 }}>
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => setConfirmOpen(true)}
              >
                Move Myself to Free Agents
              </button>
            </div>
          )}
      </div>

      <CustomConfirm
        dialog="Make yourself a free agent?%You will become available for any team captain to recruit you to their team.%You cannot undo this action."
        callback={onMoveToFreeAgents}
        isOpen={confirmOpen}
        close={setConfirmOpen}
        focused={true}
        yesNo={true}
        split="%"
      >
        <Checkbox
          onChange={() => setWaiverWire(!waiverWire)}
          value={waiverWire}
          label="Go on waiver wire"
        />
      </CustomConfirm>
    </div>
  );
};

export default ProfileInfo;
