import React, { Component } from "react";
import ReactGA from "react-ga4";
import Pagination from "../common/dataSorting/pagination";
import { paginate } from "../../utils/paginate";
import SearchBox from "../common/dataSorting/searchBox";
import orderBy from "lodash/orderBy";
import {
  getMatches,
  deleteMatch,
  emailSchedule,
  deleteMultipleMatches,
  bulkWriteMatches,
} from "../../services/matchService";
import { getCurrentUser, getReferees } from "../../services/userService";
import { getDivisions } from "../../services/divisionService";
import allowables from "../../utils/allowables";
import toast from "../../utils/toast";
import { splitQuery } from "../../utils/splitQuery";
import CustomConfirm from "../common/customs/customConfirm";
import QuickLinks from "../common/pageComponents/quickLinks";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import Checkbox from "../common/form/checkBox";
import ExcelDownload from "../common/pageComponents/excelDownload";
import MatchesButtonLineup from "./matchesButtonLineup";
import MatchesDivisionSelect from "./matchesDivisionSelect";
import MatchesTableCardSwitcher from "./matchesTableCardSwitcher";
import { navigateTo, CLink } from "./../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import NonFormSelect from "../common/form/nonFormSelect";
import CustomPrompt from "../common/customs/customPrompt";
import ModalVideoViewer from "../media/modalVideoViewer";
import MapModal from "../common/pageComponents/mapModal";
import { getFields } from "../../services/fieldService";
import IconRender from "../common/icons/iconRender";

class Matches extends Component {
  static contextType = HeaderContext;

  state = {
    matches: [],
    divisions: [],
    pageSize: allowables.pageSize,
    currentPage: 1,
    searchQuery: "",
    selectedDivision: { _id: "all", name: "All Divisions" },
    matchTypes: [],
    sortColumn: { path: "calendarDateTime", order: "asc" },
    deleteOpen: false,
    deleteDialog: "",
    selectedMatch: null,
    selectedType: "all",
    selectedMatchType: "all",
    emailScheduleOpen: false,
    sendDeleteNotifications: false,
    selectedReferee: null,
    selectedDivisions: [],
    selectedField: null,
    referees: [],
    videoUrlOpen: false,
    videoInfo: {
      modalOpen: false,
      url: "",
      header: "",
    },
    mapOpen: false,
  };

  refreshMatchesOnly = async () => {
    const response = await getMatches({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.setState({ matches: response.data });
    } else toast.error(response.data);
  };

  refreshData = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);

    await this.refreshMatchesOnly();
    const divisionsResponse = await getDivisions({
      callback: this.indicateProgress,
      bar: 1,
    });
    if (divisionsResponse.status === 200) {
      const user = getCurrentUser();
      if (user && user.role?.includes("admin")) {
        const refereesRes = await getReferees({
          callback: this.indicateProgress,
          bar: 2,
        });
        const fieldsRes = await getFields({
          callback: this.indicateProgress,
          bar: 3,
        });
        if (refereesRes.status === 200) {
          this.setState({
            referees: refereesRes.data.map((r) => {
              r.name = allowables.splitName(r.name);
              return r;
            }),
          });
        } else toast.error(refereesRes.data);
        if (fieldsRes.status === 200) {
          this.setState({ fields: fieldsRes.data });
        } else toast.error(fieldsRes.data);
      }

      this.setState({
        divisions: divisionsResponse.data,
      });
      const queryString = splitQuery(this.props.location.search);
      let searchQuery = "";
      let currentPage = 1;
      let sortColumn = { ...this.state.sortColumn };
      let selectedDivision = this.state.selectedDivision;
      let selectedType = this.state.selectedType;
      let selectedMatchType = this.state.selectedMatchType;
      queryString.forEach((q) => {
        const each = q.split("=");
        searchQuery = each[0].includes("search") ? each[1] : searchQuery;
        currentPage = each[0].includes("page") ? Number(each[1]) : currentPage;
        selectedDivision._id =
          each[0].includes("id") && each[1] !== "0"
            ? each[1]
            : each[1] === "0"
            ? 0
            : selectedDivision._id;
        selectedType = each[0].includes("type")
          ? each[1].split("%20").join(" ")
          : selectedType;
        selectedMatchType = each[0].includes("matchType")
          ? each[1].split("%20").join(" ")
          : selectedMatchType;
        selectedDivision.name = each[0].includes("name")
          ? each[1].split("%20").join(" ")
          : selectedDivision.name;
        sortColumn.path = each[0].includes("path") ? each[1] : sortColumn.path;
        sortColumn.order = each[0].includes("order")
          ? each[1]
          : sortColumn.order;
      });
      if (
        (!selectedDivision._id && selectedDivision._id !== 0) ||
        !selectedDivision.name
      )
        selectedDivision = { _id: "all", name: "All Divisions" };

      this.setState({
        searchQuery,
        currentPage,
        selectedDivision,
        sortColumn,
        selectedType,
        selectedMatchType,
      });
      this.setMatchTypes();
    } else toast.error(divisionsResponse.data);
    this.context.setLoading(false);
  };

  componentDidMount() {
    this.refreshData();
  }

  setMatchTypes = () => {
    this.setState({
      matchTypes: [
        { _id: "all", name: "All Match Types" },
        ...allowables.matchTypes.map((t) => {
          return {
            _id: t.toLowerCase(),
            name: t,
          };
        }),
      ],
    });
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  downloadColumns = [
    "dateTime",
    "homeTeamName",
    "awayTeamName",
    "homeTeamAbbreviation",
    "awayTeamAbbreviation",
    "fieldName",
    "groupName",
    "type",
    "divisionName",
    "round",
    "matchNumber",
    "refereeName",
    "sport",
  ];

  multiSelectColumns = [
    {
      content: (m) =>
        m.dateTime
          ? allowables.dateTime(m.dateTime).date +
            " " +
            allowables.dateTime(m.dateTime).time
          : "",
      label: "Date",
    },
    { content: (m) => m.homeTeamName, label: "Home Team" },
    { content: (m) => m.awayTeamName, label: "Away Team" },
    {
      content: (m) => allowables.splitName(m.refereeName),
      label: allowables.refOrUmp(this.props.org?.sport),
    },
    { content: (m) => m.fieldName, label: "Field" },
  ];

  pushHistoryString = (
    search,
    sortColumn,
    selectedDivision,
    currentPage,
    selectedType,
    selectedMatchType
  ) => {
    this.props.history.replace(
      `?search=${search}&path=${sortColumn.path}&order=${
        sortColumn.order
      }&name=${selectedDivision.name}&id=${
        selectedDivision._id
      }&page=${currentPage}&type=${
        selectedType ? selectedType : this.state.selectedType
      }&matchType=${
        selectedMatchType ? selectedMatchType : this.state.selectedMatchType
      }`
    );
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  setDeleteDialog = (match) => {
    this.setState({
      deleteDialog: `Are you sure you want to delete the match ${match.homeTeamName} vs ${match.awayTeamName}?`,
      deleteOpen: true,
      selectedMatch: match,
    });
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedMatch: match } = this.state;
    const response = await deleteMatch(
      match._id,
      {
        callback: this.indicateProgress,
        bar: 0,
      },
      this.state.sendDeleteNotifications
    );
    if (response.status === 200) {
      ReactGA.event({
        category: "Match",
        action: "delete",
        label: "deleted match from matches table",
        nonInteraction: false,
      });
      await this.refreshMatchesOnly();
    } else toast.error(response.data);
    this.setState({ sendDeleteNotifications: false });
    this.context.setLoading(false);
  };

  handlePageChange = (page) => {
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      page
    );
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage - 1
    );
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage + 1
    );
    this.setState({ currentPage: currentPage + 1 });
  };

  handleDivisionSelection = (event) => {
    const division = this.state.divisions.find(
      (d) => d._id === event.target.value
    ) || { _id: "all", name: "All Divisions" };

    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      division,
      this.state.currentPage
    );
    this.setState({
      selectedDivision: division,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSelectType = (selectedType) => {
    this.setState({ selectedType });
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      this.state.currentPage,
      selectedType
    );
  };

  handleSelectMatchType = (event) => {
    const selectedMatchType = event.target.value;
    this.setState({ selectedMatchType });
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      this.state.currentPage,
      this.state.selectedType,
      selectedMatchType
    );
  };

  handleSearch = (query) => {
    const resetDivision = { _id: "all", name: "All Divisions" };
    this.pushHistoryString(
      query,
      this.state.sortColumn,
      resetDivision,
      this.state.currentPage
    );
    this.setState({
      searchQuery: query,
      selectedDivision: resetDivision,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.pushHistoryString(
        this.state.searchQuery,
        sortColumn,
        this.state.selectedDivision,
        this.state.currentPage
      );
      this.setState({ sortColumn });
    }
  };

  handleEmailSchedule = async () => {
    if (this.state.selectedDivisions.length === 0)
      return toast.error("Please select at least one division");

    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await emailSchedule(this.state.selectedDivisions, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      ReactGA.event({
        category: "Match",
        action: "email",
        label: "emailed schedule to all players - " + this.props.org.name,
      });
      toast.success(res.data);
    } else {
      toast.error(res.data);
    }
    this.context.setLoading(false);
  };

  handleMultiDelete = async (matchIDs) => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const res = await deleteMultipleMatches(matchIDs, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success(res.data);
      await this.refreshMatchesOnly();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleBulkEdit = async (updatedMatches) => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const res = await bulkWriteMatches(updatedMatches, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success("Matches updated");
      await this.refreshMatchesOnly();
      this.context.setLoading(false);
      return true;
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleSelectDivision = (division) => {
    let { selectedDivisions } = this.state;
    const index = selectedDivisions.findIndex((d) => division._id === d);
    if (index >= 0) selectedDivisions.splice(index, 1);
    else selectedDivisions.push(division._id);
    this.setState({ selectedDivisions });
  };

  handleCardClick = (match) => {
    const user = getCurrentUser();
    navigateTo(
      user.role.includes("player")
        ? `/matches?q=${match._id}`
        : `/assignedmatches?q=${match._id}`,
      this.props.history,
      this.context
    );
  };

  handleFieldClick = (field) => {
    this.setState({ selectedField: field, mapOpen: true });
  };
  getPageData = () => {
    const {
      selectedDivision,
      matches,
      divisions,
      sortColumn,
      searchQuery,
      selectedType,
      selectedMatchType,
    } = this.state;
    const user = getCurrentUser();
    let filteredMatches = [];
    let newMatches = [...matches];
    newMatches.forEach((match) => {
      let m = { ...match };
      let search,
        division,
        matchType,
        myMatch = false;
      if (
        !searchQuery ||
        (searchQuery &&
          (m.homeTeamName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            m.awayTeamName.toLowerCase().includes(searchQuery.toLowerCase())))
      )
        search = true;
      if (
        selectedMatchType === "all" ||
        m.type.toLowerCase().includes(selectedMatchType.toLowerCase())
      )
        matchType = true;
      if (
        !selectedDivision ||
        selectedDivision._id === "all" ||
        (selectedDivision && selectedDivision._id === m.divisionID)
      )
        division = true;
      if (selectedType === "my matches" || user.role.includes("referee")) {
        if (
          user &&
          user.role.includes("player") &&
          (user.teamID === m.homeTeamID || user.teamID === m.awayTeamID)
        )
          myMatch = true;
        else if (
          user &&
          (user.role.includes("admin") || user.role.includes("referee")) &&
          m.refereeID &&
          m.refereeID._id === user._id
        )
          myMatch = true;
      }
      if (myMatch) m.userMatch = true;
      if (
        search &&
        division &&
        matchType &&
        (myMatch || (selectedType === "all" && !user.role.includes("referee")))
      )
        filteredMatches.push(m);
    });

    const sortedMatches = orderBy(
      filteredMatches,
      [sortColumn.path],
      [sortColumn.order]
    );

    const matchesByPage = paginate(
      sortedMatches,
      this.state.currentPage,
      this.state.pageSize
    );

    let dbDivisions = orderBy(divisions, ["name"], ["asc"]);
    dbDivisions.forEach((d) => {
      d.numberOfMatches = matches.filter((m) => m.divisionID === d._id).length;
    });
    dbDivisions = [
      {
        _id: "all",
        name: "All Divisions",
        numberOfMatches: matches.length,
      },
      ...dbDivisions,
    ];

    return { matchesByPage, filteredMatches, dbDivisions, sortedMatches };
  };

  render() {
    const {
      pageSize,
      currentPage,
      selectedDivision,
      matches,
      sortColumn,
      searchQuery,
      deleteOpen,
      deleteDialog,
      selectedType,
      selectedMatchType,
      emailScheduleOpen,
      sendDeleteNotifications,
      referees,
      fields,
      selectedField,
      selectedDivisions,
    } = this.state;

    const {
      matchesByPage,
      filteredMatches,
      dbDivisions: divisions,
      sortedMatches,
    } = this.getPageData();
    const user = getCurrentUser();

    return (
      <React.Fragment>
        {matches.length === 0 ? (
          user.role.includes("admin") ? (
            <div>
              <CLink
                path={"/matches?q=new"}
                button={true}
                buttonStyle="btn-lg btn-info btn-block"
              >
                Add New Match
              </CLink>
              <br />
              <CLink
                path={"/create"}
                button={true}
                buttonStyle="btn-lg btn-secondary btn-block"
              >
                Create Full Schedule
              </CLink>
            </div>
          ) : (
            <div className="text-center button-group-box">
              <p style={{ fontWeight: "bold" }}>
                There are no upcoming matches
              </p>
            </div>
          )
        ) : (
          <div>
            <HorizontalButtons
              buttons={
                user.role.includes("referee")
                  ? ["my matches"]
                  : ["all", "my matches"]
              }
              onClick={this.handleSelectType}
              selectedType={selectedType}
            />
            <br />
            <div className="row">
              <div className="col text-center">
                <NonFormSelect
                  name="_id"
                  label=""
                  options={this.state.matchTypes}
                  onChange={this.handleSelectMatchType}
                  value={selectedMatchType}
                  noDefaultOption={true}
                />
                <NonFormSelect
                  name="_id"
                  label=""
                  options={divisions}
                  onChange={this.handleDivisionSelection}
                  value={selectedDivision._id}
                  noDefaultOption={true}
                />
              </div>
              <div className="col text-center">
                <p className="haeder-p">
                  Showing {matchesByPage.length} of {filteredMatches.length}{" "}
                  matches. There are {matches.length} total matches.
                </p>
              </div>
              <div className="col text-center">
                {user.role.includes("admin") ? (
                  <MatchesButtonLineup
                    user={user}
                    matches={sortedMatches}
                    multiSelectColumns={this.multiSelectColumns}
                    onMultiDelete={this.handleMultiDelete}
                    sendNotifications={sendDeleteNotifications}
                    setSendNotifications={() =>
                      this.setState({
                        sendDeleteNotifications: !sendDeleteNotifications,
                      })
                    }
                    fields={fields}
                    referees={referees}
                    toggleModal={this.toggleModal}
                    onBulkEdit={this.handleBulkEdit}
                  />
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search by team name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={matches}
                  columns={this.downloadColumns}
                  dataName="upcoming matches"
                  tooltipDirection="top-left"
                />
              </div>
            </div>
            <MatchesTableCardSwitcher
              org={this.props.org}
              matches={matchesByPage}
              onClick={this.handleCardClick}
              onDelete={this.setDeleteDialog}
              onFieldClick={this.handleFieldClick}
              onSort={this.handleSort}
              sortColumn={sortColumn}
              location={this.props.location}
              onAddVideoUrl={(match) => {
                this.setState({
                  selectedMatch: match,
                  videoInfo: {
                    url: match.videoUrl,
                  },
                });
                this.toggleModal("videoUrlOpen");
              }}
              onViewVideo={this.handleViewVideo}
              onRefresh={this.refreshMatchesOnly}
            />
            <Pagination
              itemsCount={filteredMatches.length}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              onPreviousPage={this.handlePreviousPage}
              onNextPage={this.handleNextPage}
            />
          </div>
        )}
        <hr />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
        <CustomConfirm
          dialog={deleteDialog}
          callback={this.handleDelete}
          isOpen={deleteOpen}
          close={this.toggleModal}
          id={"deleteOpen"}
          focused={true}
          yesNo={true}
        >
          <Checkbox
            onChange={() =>
              this.setState({
                sendDeleteNotifications: !this.state.sendDeleteNotifications,
              })
            }
            value={sendDeleteNotifications}
            label="Send Push Notifications"
            tooltip={{
              header: "Send Push Notifications",
              text: `Check this box to send push notifications about this match to players and referees using the mobile app.
            By default they are not sent to allow for admins to adjust schedules peacefully.
            `,
              direction: "right",
              className: "icon-info",
            }}
          />
        </CustomConfirm>
        <CustomConfirm
          dialog={`This will email out a schedule of their upcoming matches to all players with a registered account.
                   Players and referees who have downloaded the mobile app will also receive a push notification.
                   You can do this as many times as you need but it is recommended that you only use this function once the schedule is fully complete
                   to avoid notification fatigue on the part of your users.%
                  Send out the match schedule to all your users?`}
          callback={this.handleEmailSchedule}
          isOpen={emailScheduleOpen}
          close={this.toggleModal}
          id={"emailScheduleOpen"}
          focused={true}
          split="%"
        >
          <MatchesDivisionSelect
            divisions={divisions.slice(1)}
            selectedDivisions={selectedDivisions}
            onSelectDivision={this.handleSelectDivision}
          />
        </CustomConfirm>
        <CustomPrompt
          dialog="Enter video preview URL"
          callback={this.handleAddVideoUrl}
          isOpen={this.state.videoUrlOpen}
          close={this.toggleModal}
          id="videoUrlOpen"
          initialUserInput={this.state.videoInfo.url}
          minWidth={400}
        />
        <ModalVideoViewer
          isOpen={this.state.videoInfo.modalOpen}
          onClose={() => this.setState({ videoInfo: { modalOpen: false } })}
          videoUrl={this.state.videoInfo.url}
          header={this.state.videoInfo.header}
        />
        <MapModal
          mapOpen={this.state.mapOpen}
          setMapOpen={() => this.setState({ mapOpen: false })}
          mapCenter={this.state.selectedField}
          pins={[this.state.selectedField]}
        >
          <div className="text-center">
            <div>
              <div>
                <IconRender name="search" className="icon-raised" />
                &nbsp;
                <a
                  href={`https://www.google.com/search?q=${selectedField?.address?.replace(
                    " ",
                    "+"
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedField?.address}
                </a>
              </div>
              <div>
                <a
                  href={selectedField?.webAddress}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedField?.webAddress}
                </a>
              </div>
            </div>
          </div>
        </MapModal>
      </React.Fragment>
    );
  }
}

export default Matches;
