import http from "./httpService";
import jwtDecode from "jwt-decode";
import { uploadAvatar, deleteAvatar } from "./ftpService";
import Cookies from "js-cookie";
import { callbackProgress } from "./../utils/progress";
import allowables from "../utils/allowables";

const tokenKey = "token";
const refereeCodeKey = "code";
const cookieKey = "cookiesAccepted";

http.setJwt(getJwt());

export function setUser(jwt) {
  Cookies.set(tokenKey, jwt, allowables.cookieOptions);
  Cookies.remove(refereeCodeKey);
  http.setJwt(jwt);
}

export function getJwt() {
  return Cookies.get(tokenKey);
}

export function setRefereeCode(code) {
  if (code) {
    Cookies.set(refereeCodeKey, JSON.stringify(code));
  } else Cookies.remove(refereeCodeKey);
}
export function getRefereeCode() {
  const code = Cookies.get(refereeCodeKey);
  if (code) return JSON.parse(code);
  else return null;
}

export function getCurrentUser() {
  try {
    let user = jwtDecode(Cookies.get(tokenKey));
    user.firstName = user.name.split("%20%")[0];
    user.lastName = user.name.split("%20%")[1] || "";
    return user;
  } catch (ex) {
    return null;
  }
}

export function getCookieAcceptance() {
  const cookieAcceptance = Cookies.get(cookieKey);
  if (cookieAcceptance === "true") return true;
  else return false;
}

export function acceptCookies() {
  let options = { ...allowables.cookieOptions };
  options.expires = 182;
  Cookies.set(cookieKey, "true", options);
}

export function setDontShowMobilePrompt(expiresIn) {
  let options = { ...allowables.cookieOptions };
  options.expires = expiresIn;
  Cookies.set("dontShowMobilePrompt", "true", options);
}

export function showMobilePrompt() {
  const dontShow = Cookies.get("dontShowMobilePrompt");
  return dontShow === "true";
}

export async function revokeCookieAcceptance(reload) {
  Cookies.remove(cookieKey);
  Cookies.remove(tokenKey);
  Cookies.remove("spectatingOrg");
  if (reload) window.location.reload();
}

export async function registerUser(user, callback) {
  try {
    const response = await http.post(
      http.usersEndpoint,
      user,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function registerNewAdminAccount(user, callback) {
  try {
    const response = await http.post(
      http.usersEndpoint + "/newadminaccount",
      user,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function loginUser(user, callback) {
  try {
    const response = await http.post(
      http.authEndpoint,
      user,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.data);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function refreshUser(callback) {
  try {
    const response = await http.get(
      http.usersEndpoint + "/me",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export function logout() {
  localStorage.clear();
  Cookies.remove(tokenKey);
}

export async function updateUserInfo(user, callback) {
  try {
    const response = await http.put(
      http.usersEndpoint + "/me",
      user,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.headers["x-auth-token"]);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function reviewVaccines(users, forceApprove, callback) {
  try {
    return await http.put(
      http.usersEndpoint + "/vaccine",
      { users, forceApprove },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getUsersForOrg(callback) {
  const user = getCurrentUser();
  try {
    return await http.get(
      http.usersEndpoint + "/users/" + user.orgID,
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getReferees(callback) {
  try {
    return await http.get(
      http.usersEndpoint + "/referees",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function saveAvatar(avatar) {
  try {
    return await uploadAvatar(avatar);
  } catch (ex) {
    return ex.reponse;
  }
}

export async function removeAvatar(user) {
  try {
    return await deleteAvatar(user);
  } catch (ex) {
    return ex.response;
  }
}

export async function requestPasswordReset(email, callback) {
  try {
    return await http.put(
      http.usersEndpoint + "/passwordreset/" + email,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function updatePassword(token, user, callback) {
  try {
    return await http.put(
      http.usersEndpoint + "/passwordupdate/" + token,
      user,
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function requestLoginCode(email, callback) {
  try {
    return await http.post(
      http.authEndpoint + "/requestcode",
      { email },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function loginWithCode(email, code, callback) {
  try {
    const response = await http.post(
      http.authEndpoint + "/loginwithcode",
      {
        email,
        code,
      },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );

    setUser(response.data);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function removeUserFromOrg(user, callback) {
  try {
    return await http.put(
      http.usersEndpoint + "/org/remove/" + user._id,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function getUserAccounts(callback) {
  try {
    return await http.get(
      http.usersEndpoint + "/profile/me",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function switchAccount(account, callback) {
  try {
    const response = await http.post(
      http.usersEndpoint + "/switchaccount/" + account._id,
      {},
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.data);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function deleteUserAndSwitchToNextAccount(callback) {
  try {
    const response = await http.delete(
      http.usersEndpoint + "/deleteandswitch",
      callback && {
        onDownloadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(response.data.token);
    return response;
  } catch (ex) {
    return ex.response;
  }
}

export async function searchForAccount(name, email, password, callback) {
  try {
    const res = await http.post(
      http.usersEndpoint + "/search/accounts",
      { name, email, password },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(res.headers["x-auth-token"]);
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export async function registerFreeAgent(user, formType, callback) {
  try {
    const res = await http.post(
      http.usersEndpoint + "/freeagent",
      { user, formType },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(res.headers["x-auth-token"]);
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export async function registerTeamCaptain(user, formType, callback) {
  try {
    const res = await http.post(
      http.usersEndpoint + "/teamcaptain",
      { user, formType },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(res.headers["x-auth-token"]);
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export function getUserLocation(callback) {
  try {
    navigator.geolocation.getCurrentPosition((position) => {
      callback({
        retrieved: true,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    }, callback({ retrieved: false }));
  } catch (ex) {
    callback({ retrieved: false });
  }
}

export async function moveSelfToFreeAgents(waiverWire, callback) {
  try {
    const res = await http.put(
      http.usersEndpoint + "/me/transfer",
      { waiverWire },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
    setUser(res.headers["x-auth-token"]);
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export async function updateCustomFlags(index, users, callback) {
  try {
    return await http.put(
      http.usersEndpoint + "/customflag/update/" + index,
      { users },
      callback && {
        onUploadProgress: function (progress) {
          callbackProgress(progress, callback);
        },
      }
    );
  } catch (ex) {
    return ex.response;
  }
}

export async function postPrepaymentSuccess(successID) {
  try {
    const res = await http.post(
      http.paymentEndpoint + "/prepayment/success/" + successID
    );
    setUser(res.headers["x-auth-token"]);
    return res;
  } catch (ex) {
    return ex.response;
  }
}

export async function unsubscribe(userID) {
  try {
    return await http.post(http.profilesEndpoint + "/unsubscribe/" + userID);
  } catch (ex) {
    return ex.response;
  }
}

export async function updateNotificationSettings(settings) {
  try {
    return await http.put(
      http.usersEndpoint + "/notificationsettings",
      settings
    );
  } catch (ex) {
    return ex.response;
  }
}
