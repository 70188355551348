import React, { useState, useEffect, useContext } from "react";
import toast from "../../utils/toast";
import { useHistory } from "react-router-dom";

import PreMatchSegment from "../assignedMatch/preMatchSegment";
import NotFound from "../notFound";
import allowables from "../../utils/allowables";
import HeaderContext from "./../../context/headerContext";
import LiveScorecard from "../scorecards/liveScorecard";
import { getMatch, toggleCheckIn } from "../../services/matchService";
import { getMatchNoAuth } from "../../services/spectateService";
import { getPlayersForMatch } from "../../services/playerService";
import { getTeamsForMatch } from "../../services/teamService";
import { getLiveScorecard } from "../../services/liveScoreService";
import LiveInfo from "../scorecards/liveInfo";
import { navigateTo } from "../common/customs/customLinks";
import QRCodeRender from "../common/pageComponents/qrCode";
import ReviewHeaderSegment from "../matchReview/reviewHeaderSegment";
import ReviewInfoSegment from "../matchReview/reviewInfoSegment";
import CheckinInfo from "./checkinInfo";

const MatchInfoView = ({
  matchID,
  org,
  spectate,
  authed = true,
  refreshInterval = 5000,
}) => {
  const history = useHistory();
  const header = useContext(HeaderContext);
  const [originalMatch, setOriginalMatch] = useState(null);
  const [teams, setTeams] = useState({ home: [], away: [] });
  const [players, setPlayers] = useState({ home: [], away: [] });
  const [teamOrder, setTeamOrder] = useState(["home", "away"]);

  const [liveScorecard, setLiveScorecard] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(null);

  const getData = async (hideLoading) => {
    header.setLoading(!hideLoading);
    header.setProgress([0, 0, 0]);

    let isLive = true;

    if (authed) {
      const matchRes = await getMatch(matchID, {
        callback: (p) => header.setProgress(p),
        bar: 0,
      });
      if (matchRes.status === 200) {
        const match = matchRes.data;

        // check if match is complete, if so navigate to review page
        if (match.matchComplete === 1)
          return navigateTo(`/matchreview?q=${matchID}`, history, header);
        // fetch players from db
        const playersRes = await getPlayersForMatch(
          match.homeTeamID,
          match.awayTeamID,
          { callback: (p) => header.setProgress(p), bar: 1 }
        );
        if (playersRes.status === 200) {
          // fetch teams from db (to get team colors)
          const teamsRes = await getTeamsForMatch(
            match.homeTeamID,
            match.awayTeamID,
            { callback: (p) => header.setProgress(p), bar: 2 }
          );
          if (teamsRes.status === 200) {
            // set players, teams, match, approvals
            const allPlayers = playersRes.data;
            setPlayers({
              home: allPlayers.filter((p) => p.teamID === match.homeTeamID),
              away: allPlayers.filter((p) => p.teamID === match.awayTeamID),
            });
            setTeams({
              home: teamsRes.data.find((t) => t._id === match.homeTeamID),
              away: teamsRes.data.find((t) => t._id === match.awayTeamID),
            });
            setOriginalMatch(match);
            setTeamOrder(allowables.teams(match.sport));
          } else toast.error(teamsRes.data);
        } else toast.error(playersRes.data);
      } else toast.error(matchRes.data);
    } else {
      const matchRes = await getMatchNoAuth(matchID, {
        callback: (p) => header.setProgress(p),
        bar: 0,
      });
      if (matchRes.status === 200) {
        if (matchRes.data.matchComplete === 1) isLive = false;
        setOriginalMatch(matchRes.data);
        setTeamOrder(allowables.teams(matchRes.data.sport));
      } else toast.error(matchRes.data);
    }

    if (!refreshTimer && isLive) {
      if (org.misc?.liveScorecards) {
        getLiveScorecardData(true);
        setRefreshTimer(
          setInterval(() => {
            getLiveScorecardData();
          }, refreshInterval)
        );
      }
    }

    header.setLoading(false);
  };

  const getLiveScorecardData = async (firstPull) => {
    const liveRes = await getLiveScorecard(
      matchID,
      authed,
      org.spectatingAllowed
    );
    if (liveRes?.status === 200) {
      setLiveScorecard(liveRes.data);
    } else if (liveRes.status === 429) {
      // escape to the matches page if this happens
      navigateTo("/matches", history, header);
    } else {
      setLiveScorecard(null);
      // get data is run again here to catch the scorecard update when the status goes from live to complete
      if (!firstPull) getData(true);
    }
  };

  const handleCheckIn = async (notPlaying = false) => {
    header.setLoading(true);
    const res = await toggleCheckIn(matchID, notPlaying);
    if (res.status === 200) {
      if (res.data.includes("in")) toast.success("Attending");
      else toast.info("NOT attending");
      return getData();
    } else if (res.status === 429) {
      // special use case of login limiter
      toast.dismissAll();
      toast.error(
        "You are doing that too much, please wait one minute and try again"
      );
    } else toast.error(res.data);
    header.setLoading(false);
  };

  useEffect(() => {
    getData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchID]);

  useEffect(() => {
    return () => {
      clearInterval(refreshTimer);
    };
  }, [refreshTimer]);

  if (!originalMatch) return <NotFound text="This match was not found" />;

  return (
    <div className="centered-small-input-area">
      {originalMatch.matchComplete ? (
        <div>
          <ReviewHeaderSegment
            org={org}
            data={originalMatch}
            history={history}
            teams={teamOrder}
            spectate={spectate}
          />
          <ReviewInfoSegment data={originalMatch} teams={teamOrder} />
        </div>
      ) : (
        <div>
          {!liveScorecard ? (
            <CheckinInfo
              onCheckin={handleCheckIn}
              match={originalMatch}
              teamOrder={teamOrder}
            />
          ) : null}
          <LiveInfo liveData={liveScorecard} org={org} />
          {!liveScorecard ? (
            <div>
              <PreMatchSegment
                originalMatch={originalMatch}
                teamOrder={teamOrder}
                teams={teams}
                players={players}
                spectate={spectate}
              />
            </div>
          ) : (
            <LiveScorecard
              matchData={originalMatch}
              liveData={liveScorecard}
              teamOrder={teamOrder}
              refreshInterval={refreshInterval}
              spectate={spectate}
              org={org}
            />
          )}
        </div>
      )}
      <QRCodeRender
        value={allowables.getLiveScorecardUrl(originalMatch._id, org)}
        margin={15}
      />
    </div>
  );
};

export default MatchInfoView;
