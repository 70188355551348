import { tennisGameScoreProg } from "ultimatescoreboard-shared";
import allowables from "./allowables";

export function getAccountText(account, key) {
  return (
    <div key={key}>
      {allowables.ballIcon(allowables.translateSportName(account.orgID))}
      &nbsp;
      <b>{account.orgName || "Create a New League"}</b>
      <br />
      {allowables.translateRole(account.role, account.sport)}
    </div>
  );
}

export function renderFlexSection(
  sectionFlex = [],
  texts = [],
  styles = [],
  containerStyle = {}
) {
  return (
    <div style={{ display: "flex", ...containerStyle }}>
      {sectionFlex.map((flexPct, idx) => (
        <div key={idx} style={{ flex: `${flexPct}%`, ...(styles[idx] || {}) }}>
          {texts[idx]}
        </div>
      ))}
    </div>
  );
}

export function renderStrikeout(count) {
  return (
    <div style={{ display: "inline-block" }}>
      <div
        className="flip-text icon-mobile"
        style={{ display: "inline-block", fontWeight: "bold" }}
      >
        K
      </div>
      &nbsp;
      {count}
    </div>
  );
}

export function mapTennisPoints(score, index) {
  return score.inTiebreak ? index : tennisGameScoreProg[index];
}
