import React, { Component } from "react";
import NonFormSelect from "../common/form/nonFormSelect";
import { getLeaderboard } from "../../services/playerService";
import SearchBox from "../common/dataSorting/searchBox";
import DiamondLeaderboardTooltip from "./diamond/diamondLeaderboardTooltip";
import ExcelDownload from "../common/pageComponents/excelDownload";
import { soccerLeaderboardDownload } from "../../utils/soccerFunctions";
import { footballLeaderboardDownload } from "../../utils/footballFunctions";
import { diamondLeaderboardDownload } from "../../utils/diamondFunctions";
import QRCodeRender from "../common/pageComponents/qrCode";
import HeaderContext from "../../context/headerContext";
import SeasonDisplay from "./seasonDisplay";
import SportLeaderboard from "./sportLeaderboard";
import WarningHeader from "../common/pageComponents/warningHeader";

class Leaderboards extends Component {
  static contextType = HeaderContext;
  state = {
    matches: 0,
    leaderboard: [],
    divisions: this.props.divisions,
    players: 0,
    selectedDivision: this.props.divisionID,
    searchQuery: "",
    sortColumn: { path: "goals", order: "desc" },
    selectedTable: "batting",
  };

  async componentDidMount() {
    if (this.props.divisionID)
      await this.handleChange({ target: { value: this.props.divisionID } });
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleChange = async (event) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { value: divisionID } = event.target;
    if (divisionID === "") return this.context.setLoading(false);
    const response = await getLeaderboard(divisionID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) {
      this.props.onDivisionChange(divisionID);
      this.setState({
        players: response.data.players,
        leaderboard: response.data.leaderboard || [],
        matches: response.data.matches,
        selectedDivision: divisionID,
      });
    }
    this.context.setLoading(false);
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  handleSelectTable = (selectedTable) => {
    this.setState({ selectedTable });
  };

  render() {
    const {
      selectedDivision,
      divisions,
      matches,
      players,
      searchQuery,
      leaderboard,
    } = this.state;

    const sport = divisions[0] && divisions[0].sport.toLowerCase();
    const excelData = sport.includes("soccer")
      ? soccerLeaderboardDownload(leaderboard)
      : sport.includes("football")
      ? footballLeaderboardDownload(leaderboard)
      : sport.includes("diamond")
      ? diamondLeaderboardDownload(leaderboard)
      : {};

    const currentDivision = divisions.find((d) => d._id === selectedDivision);

    return (
      <div>
        <SeasonDisplay season={currentDivision?.seasonID} />
        <div className="row">
          <div className="col">
            <NonFormSelect
              name="division"
              label="Select Division:&nbsp;"
              options={divisions}
              onChange={this.handleChange}
              value={selectedDivision}
            />
          </div>
          {sport.includes("diamond") && (
            <div className="col-2">
              <DiamondLeaderboardTooltip
                selectedTable={this.state.selectedTable}
              />
            </div>
          )}
          {this.props.qrLink && (
            <div className="col-3">
              <QRCodeRender
                value={`${this.props.qrLink}${selectedDivision}&option=leaderboards`}
                size={100}
              />
            </div>
          )}
        </div>
        {selectedDivision && matches === 0 && (
          <p>There have been no official matches completed in this division.</p>
        )}
        {selectedDivision && matches !== 0 && players === 0 && (
          <p>
            There are no players added to teams in this division. Individual
            statistics are not being tracked.
          </p>
        )}
        {selectedDivision && sport.includes("simple") && (
          <WarningHeader>
            Leaderboards are not tracked for leagues using the simple scoring
            option
          </WarningHeader>
        )}
        {leaderboard.length !== 0 && players.length !== 1 && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <SearchBox
                  value={searchQuery}
                  onChange={this.handleSearch}
                  placeholder="Search player or team name..."
                />
              </div>
              <div className="col-2">
                <ExcelDownload
                  data={excelData}
                  columns={Object.keys(excelData[0])}
                  dataName="leaderboard"
                  tooltipDirection="top-left"
                />
              </div>
            </div>

            <SportLeaderboard
              sport={sport.toLowerCase()}
              leaderboard={leaderboard}
              searchQuery={searchQuery}
              onSelectTable={this.handleSelectTable}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Leaderboards;
