import React, { Component } from "react";
import toast from "../../../utils/toast";
import { loadStripe } from "@stripe/stripe-js";
import ReactGA from "react-ga4";

import PlayerPaymentForm from "./playerPaymentForm";
import {
  getAccountEnabledInfo,
  getOrgPaymentData,
  startCheckoutSession,
  acceptPayment,
} from "../../../services/paymentService";
import { getDiscount } from "../../../services/discountService";
import { stripeKey } from "../../../utils/paymentsUtil";
import { getPlayer } from "./../../../services/playerService";
import { getTeam } from "../../../services/teamService";
import QuickLinks from "../../common/pageComponents/quickLinks";
import HeaderContext from "../../../context/headerContext";
import WarningHeader from "../../common/pageComponents/warningHeader";

class PlayerPaymentHome extends Component {
  static contextType = HeaderContext;
  state = {
    hasPlayer: false,
    player: null,
    team: null,
    paymentDetails: null,
    paymentHistory: null,
    paymentsEnabled: false,
    paymentData: null,
    error: "",
    discount: null,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const param = this.props.history.location.search;
    if (param && !param.includes("cancel")) {
      const acceptPaymentRes = await acceptPayment(param.slice(1));
      if (acceptPaymentRes.status === 200) {
        toast.success(acceptPaymentRes.data);
      } else toast.error(acceptPaymentRes.data);
      this.props.history.replace(this.props.location.pathname);
    }

    if (
      !this.props.user ||
      !this.props.user.playerID ||
      !this.props.user.teamID
    ) {
      this.setState({ hasPlayer: false });
    } else {
      const playerRes = await getPlayer(this.props.user?.playerID, {
        callback: this.indicateProgress,
        bar: 0,
      });

      const teamRes =
        this.props.user.teamName === "Free Agents"
          ? {
              status: 200,
              data: {
                name: "Free Agents",
              },
            }
          : await getTeam(this.props.user?.teamID, {
              callback: this.indicateProgress,
              bar: 1,
            });
      const accountRes = await getAccountEnabledInfo();
      const paymentRes = await getOrgPaymentData();

      if (paymentRes.status === 200) {
        if (playerRes.status === 200) {
          if (teamRes.status === 200) {
            if (accountRes.status === 200) {
              const discountRes = await getDiscount(
                this.props.org.stripe.whoPays === "Player"
                  ? playerRes.data.discountApplied
                  : teamRes.data.discountApplied
              );
              let discount = null;
              if (discountRes?.status === 200)
                discount = discountRes?.data?.active ? discountRes?.data : null;

              this.setState({
                hasPlayer: true,
                player: playerRes.data,
                team: teamRes.data,
                discount,
                paymentDetails:
                  this.props.org.stripe.whoPays === "Team"
                    ? teamRes.data.payment
                    : this.props.org.stripe.whoPays === "Player"
                    ? playerRes.data.payment
                    : null,
                paymentHistory:
                  this.props.org.stripe.whoPays === "Team"
                    ? teamRes.data.paymentHistory
                    : playerRes.data.paymentHistory,
                paymentData: paymentRes.data,
              });
              if (accountRes.data) this.setState({ paymentsEnabled: true });
            } else {
              this.setState({ error: accountRes.data });
            }
          } else {
            this.setState({ error: teamRes.data });
          }
        } else {
          this.setState({ error: playerRes.data });
        }
      } else {
        this.setState({ error: paymentRes.data });
      }
    }
    this.context.setLoading(false);
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleApplyDiscount = (discount, reapply) => {
    if (reapply) this.componentDidMount();
    else this.setState({ discount });
  };

  getCheckoutSession = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);

    const checkoutRes = await startCheckoutSession(this.state.discount?.code);
    if (checkoutRes.status === 200) {
      if (checkoutRes.data.paidInFull) {
        toast.success("Paid");
        return await this.componentDidMount();
      } else {
        try {
          const stripePromise = await loadStripe(stripeKey(), {
            stripeAccount: checkoutRes.data.accountID,
          });
          return stripePromise.redirectToCheckout({
            sessionId: checkoutRes.data.sessionID,
          });
        } catch (error) {
          toast.error("Could not connect to Stripe. Please try again later");
          ReactGA.event({
            category: "Payment",
            action: `Error during redirectToCheckout: ${error.message}`,
            label: "Error from Stripe",
            nonInteraction: false,
          });
        }
      }
    } else {
      toast.error(checkoutRes.data);
      this.setState({ error: checkoutRes.data });
    }
    this.context.setLoading(false);
  };

  render() {
    const {
      hasPlayer,
      paymentsEnabled,
      error,
      player,
      team,
      discount,
      paymentData,
      paymentDetails,
      paymentHistory,
    } = this.state;

    return (
      <div>
        <WarningHeader>
          {error ||
            (!hasPlayer
              ? "You must have a player account set up in order to make payments"
              : !paymentsEnabled
              ? "Your League is not set up to accept player payments"
              : "")}
        </WarningHeader>
        {paymentsEnabled ? (
          <PlayerPaymentForm
            org={this.props.org}
            player={player}
            paymentData={paymentData}
            startCheckoutSession={this.getCheckoutSession}
            paymentDetails={paymentDetails}
            paymentHistory={paymentHistory}
            user={this.props.user}
            team={team}
            appliedDiscount={discount}
            onApplyDiscount={this.handleApplyDiscount}
          />
        ) : null}
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
      </div>
    );
  }
}

export default PlayerPaymentHome;
