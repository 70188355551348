import React, { useState } from "react";

import BasicModal from "./../../common/pageComponents/basicModal";
import allowables from "../../../utils/allowables";
import { FiExternalLink } from "react-icons/fi";
import MiniHeader from "../../common/pageComponents/miniHeader";
import { popupStyle } from "../../../utils/styleUtil";

const PlayerPaymentHistory = ({
  user,
  paymentData,
  payment,
  paymentHistory,
}) => {
  const [open, setOpen] = useState(false);

  if (
    (paymentData.whoPays === "Team" && !user.role.includes("captain")) ||
    (!paymentHistory?.length && !payment)
  )
    return null;

  const displayPayment = (p) => {
    if (!p || !p.datePaid || isNaN(Number(p.amountPaid)) || p.amountPaid === 0)
      return null;
    return (
      <div className="text-center">
        <p>
          <b>Date</b>: {allowables.dateTime(p.datePaid).date}
        </p>
        <p>
          <b>Amount:</b> {p.amountPaid} {p.currency?.toUpperCase()}
        </p>
        <p>
          <a href={p.receiptUrl} target="_blank" rel="noopener noreferrer">
            View Receipt <FiExternalLink />
          </a>
        </p>
        <hr />
      </div>
    );
  };

  return (
    <div style={{ margin: 5 }}>
      <button
        className="btn btn-sm btn-secondary"
        onClick={() => setOpen(!open)}
      >
        Receipts
      </button>
      <BasicModal
        header={<MiniHeader>Payment Receipts</MiniHeader>}
        isOpen={open}
        onClose={setOpen}
        style={popupStyle}
      >
        <div className="form-divided-section">
          <h5 className="text-center">
            <b>Current Season</b>
          </h5>
          {displayPayment(payment)}
          <h5 className="text-center">
            <b>Older Payments</b>
          </h5>
          {paymentHistory &&
            paymentHistory.reverse().map((p, i) => {
              return (
                <React.Fragment key={i}>{displayPayment(p)}</React.Fragment>
              );
            })}
        </div>
      </BasicModal>
    </div>
  );
};

export default PlayerPaymentHistory;
