import React, { Component } from "react";
import ReactGA from "react-ga4";
import toast from "../../utils/toast";
import allowables from "../../utils/allowables";
import ReviewApprovalSegment from "./reviewApprovalSegment";
import ReviewHeaderSegment from "./reviewHeaderSegment";
import ReviewInfoSegment from "./reviewInfoSegment";
import ReviewButtonsSegment from "./reviewButtonsSegment";
import CustomConfirm from "../common/customs/customConfirm";
import CustomAlert from "../common/customs/customAlert";
import HeaderContext from "../../context/headerContext";
import NotFound from "./../notFound";
import QRCodeRender from "../common/pageComponents/qrCode";

class CompletedMatchForm extends Component {
  static contextType = HeaderContext;
  state = {
    data: {
      _id: "",
      homeTeamName: "",
      homeTeamGoals: "",
      awayTeamName: "",
      awayTeamGoals: "",
      matchAccepted: "",
      homeTeamPKs: "",
      awayTeamPKs: "",
      homeTeamProtest: false,
      awayTeamProtest: false,
      homeTeamYellowCardsID: "",
      awayTeamYellowCardsID: "",
      homeTeamProtestString: "",
      awayTeamProtestString: "",
      homeTeamCaptainID: "",
      awayTeamCaptainID: "",
      sport: "",
    },
    homeTeamCaptain: null,
    awayTeamCaptain: null,
    errors: {},
    baseState: {},
    removeComments: false,
    fullyApprove: false,
    org: {},
    protestText: "",
    protestOpen: false,
    restartOpen: false,
    restartDialog:
      "Are you sure you want to restart this match? All stats will be reset to zero and the match will be sent back to Upcoming Matches.",
    acceptOpen: false,
    protestDialog: "",
    protestConfirmOpen: false,
    removeProtestOpen: false,
    alertOpen: false,
    autoPopulate: true,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const errorMessage =
      ". Could not load all match info, please refresh page.";
    const user = this.props.getCurrentUser();
    const search = this.props.location.search
      .split("&")
      .find((q) => q.includes("co="));
    // const matchID = this.props.match.params.matchID
    const matchID = this.props.matchID;
    const match = await this.props.getMatch(matchID, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (match.status === 200) {
      const players = await this.props.getPlayersForMatch(
        match.data.homeTeamID,
        match.data.awayTeamID,
        {
          callback: this.indicateProgress,
          bar: 1,
        }
      );
      if (players.status === 200) {
        const protestText =
          user._id === match.data.homeTeamCaptainID
            ? match.data.homeTeamProtestString || ""
            : user._id === match.data.awayTeamCaptainID
            ? match.data.awayTeamProtestString || ""
            : "";
        const defaultApproval =
          this.props.org.misc && this.props.org.misc.defaultApproval;
        this.setState({
          org: this.props.org,
          data: match.data,
          baseState: match.data,
          protestText,
          homeTeamCaptain: players.data.find(
            (p) => p.userID && p.userID._id === match.data.homeTeamCaptainID
          ),
          awayTeamCaptain: players.data.find(
            (p) => p.userID && p.userID._id === match.data.awayTeamCaptainID
          ),
          fullyApprove:
            defaultApproval === "Both" || defaultApproval === "Fully Approve",
          removeComments:
            defaultApproval === "Both" || defaultApproval === "Remove Comments",
        });
      } else toast.error(players.data + errorMessage);
    } else return this.props.history.replace("/not-found");

    this.setState({ protestOpen: search ? true : false });
    this.context.setLoading(false);
  }

  mapToViewModel(match) {
    return {
      divisionName: match.divisionName || "",
      homeTeamName: match.homeTeamName || "",
      awayTeamName: match.awayTeamName || "",
      dateTime: match.dateTime || "",
      refereeEmail: match.refereeEmail || "",
      fieldName: match.fieldName || "",
    };
  }

  restartMatch = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { data: match } = this.state;
    let accepted = false;
    if (this.state.data.matchAccepted === 1) {
      accepted = true;
    }
    const response = await this.props.restartMatch(match, accepted, {
      callback: this.indicateProgress,
      state: "progress",
      bar: 0,
    });
    if (response.status === 200) {
      return this.props.history.replace("/matches");
    } else {
      ReactGA.event({
        category: "Match",
        action: "restart match failed",
        label: response.data,
        nonInteraction: true,
      });
      toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  acceptMatch = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const { data: match } = this.state;
    if (this.state.fullyApprove) {
      match.homeTeamProtest = false;
      match.awayTeamProtest = false;
    }
    if (this.state.removeComments) {
      match.homeTeamProtestString = "";
      match.awayTeamProtestString = "";
      match.refereeComments = "";
    }
    const response = await this.props.acceptMatch(
      { ...match, autoPopulate: this.state.autoPopulate },
      {
        callback: this.indicateProgress,
        state: "progress",
        bar: 0,
      }
    );
    if (response.status === 200) {
      return this.props.history.replace("/matchreview");
    } else {
      ReactGA.event({
        category: "Match",
        action: "accept match failed",
        label: response.data,
        nonInteraction: true,
      });
      toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  openAlert = (dialog) => {
    this.setState({ alertDialog: dialog, alertOpen: true });
  };

  setProtestDialog = () => {
    const protestText = this.state.protestText;
    if (protestText.length < 10)
      return this.openAlert("Please provide a longer comment");
    const match = this.state.data;
    const user = this.props.getCurrentUser();
    let teamString = "";
    if (match.homeTeamCaptainID === user._id)
      teamString = "homeTeamProtestString";
    else if (match.awayTeamCaptainID === user._id)
      teamString = "awayTeamProtestString";
    else
      return this.openAlert(
        "Only the team captain can submit an official comment."
      );
    this.setState({
      protestDialog:
        `Are you sure you want to submit this comment:%"${protestText}"?` +
        (match[teamString]
          ? `%This will replace your previous comment:%${match[teamString]}`
          : ""),
      protestConfirmOpen: true,
    });
  };

  submitProtest = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const protestText = this.state.protestText;
    const match = this.state.data;
    const user = this.props.getCurrentUser();
    let teamString = "";
    if (match.homeTeamCaptainID === user._id)
      teamString = "homeTeamProtestString";
    else if (match.awayTeamCaptainID === user._id)
      teamString = "awayTeamProtestString";
    if (match[teamString] === protestText)
      toast.dark("This comment has already been submitted");
    else {
      match[teamString] = protestText;
      const response = await this.props.submitMatchComment(match, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (response.status === 200) toast.success("Submitted");
      else toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  clearProtest = async () => {
    const match = this.state.data;
    const user = this.props.getCurrentUser();
    let teamString = "";
    if (match.homeTeamCaptainID === user._id) teamString = "homeTeamProtest";
    else if (match.awayTeamCaptainID === user._id)
      teamString = "awayTeamProtest";
    else
      return this.openAlert(
        "Only the team captain can manage official comments."
      );
    if (!match[teamString + "String"])
      return this.setState({ protestText: "" });
    this.context.setLoading(true);
    this.context.setProgress([1]);
    match[teamString + "String"] = "";
    const response = await this.props.submitMatchComment(match, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) toast.success("Comment Removed");
    else toast.error(response.data);
    this.setState({ protestText: "" });
    this.context.setLoading(false);
  };

  openProtests = () => {
    const protestOpen = this.state.protestOpen ? false : true;
    this.props.history.replace(
      this.props.history.location.pathname +
        "?q=" +
        this.props.matchID +
        (protestOpen ? `&co=true` : "")
    );
    setTimeout(() => {
      this.context.setLoading(false);
    });
    this.setState({ protestOpen });
  };

  handleProtestTextChange = (value) => {
    this.setState({ protestText: value });
  };

  handleApprovalChange = (type) => {
    this.setState({ [type]: this.state[type] ? false : true });
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  render() {
    const {
      data,
      org,
      protestOpen,
      protestText,
      removeComments,
      fullyApprove,
      restartOpen,
      restartDialog,
      acceptOpen,
      alertDialog,
      alertOpen,
      protestDialog,
      protestConfirmOpen,
      removeProtestOpen,
      autoPopulate,
    } = this.state;
    const user = this.props.getCurrentUser();

    const teams = allowables.teams(data.sport);

    if (this.context.loading) return null;

    if (!data._id)
      return (
        <NotFound
          text="Match could not be found"
          history={this.props.history}
        />
      );

    return (
      <div className="centered-small-input-area">
        <ReviewApprovalSegment
          user={user}
          teams={teams}
          org={org}
          openProtests={this.openProtests}
          protestOpen={protestOpen}
          data={data}
          protestText={protestText}
          textChange={this.handleProtestTextChange}
          clearProtest={() => this.toggleModal("removeProtestOpen")}
          submitProtest={this.setProtestDialog}
        />
        <ReviewHeaderSegment
          org={org}
          data={data}
          history={this.props.history}
          teams={teams}
        />
        <ReviewInfoSegment data={data} teams={teams} />
        <ReviewButtonsSegment
          user={user}
          data={data}
          restartMatch={() => this.toggleModal("restartOpen")}
          acceptMatch={() => this.toggleModal("acceptOpen")}
          org={org}
          removeComments={removeComments}
          fullyApprove={fullyApprove}
          onApprovalChange={this.handleApprovalChange}
          autoPopulate={autoPopulate}
        />
        <QRCodeRender
          value={allowables.getLiveScorecardUrl(data._id, org)}
          margin={15}
        />
        <br />
        <br />
        <br />
        <br />
        <CustomConfirm
          dialog={
            restartDialog +
            (data.matchAccepted === 0
              ? ""
              : "%Any suspensions served during this match will not be reset, but cards earned during this match will be removed.") +
            (data.metadata?.bracketID
              ? `%This match is part of a bracket that uses autopoulation.
              Restarting this match will not remove the teams from the next matches, that will have to be done manually.`
              : "")
          }
          callback={this.restartMatch}
          isOpen={restartOpen}
          close={this.toggleModal}
          id={"restartOpen"}
          focused={true}
          yesNo={true}
          split={data.matchAccepted === 0 ? "" : "%"}
        />
        <CustomConfirm
          dialog={
            "Are you sure all the match details are correct? Once accepted these details cannot be changed."
          }
          callback={this.acceptMatch}
          isOpen={acceptOpen}
          close={this.toggleModal}
          id={"acceptOpen"}
          yesNo={true}
        />
        <CustomConfirm
          dialog={protestDialog}
          callback={this.submitProtest}
          isOpen={protestConfirmOpen}
          close={this.toggleModal}
          id={"protestConfirmOpen"}
          yesNo={true}
          split="%"
        />
        <CustomConfirm
          dialog={
            "Are you sure you want to remove your comment from this match?"
          }
          callback={this.clearProtest}
          isOpen={removeProtestOpen}
          close={this.toggleModal}
          id={"removeProtestOpen"}
          yesNo={true}
        />
        <CustomAlert
          dialog={alertDialog}
          isOpen={alertOpen}
          close={this.toggleModal}
          id="alertOpen"
        />
      </div>
    );
  }
}

export default CompletedMatchForm;
