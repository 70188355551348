import React from "react";
import Form from "../../common/form/form";
import Joi from "joi-browser";

import toast from "../../../utils/toast";
import ComponentLoading from "../../common/loading/componentLoading";
import { getDiscountByCode } from "../../../services/discountService";

class PlayerDiscountForm extends Form {
  state = {
    data: { code: "" },
    errors: {},
    loading: false,
  };

  schema = {
    code: Joi.string().optional().min(4).max(25).label("Discount Code"),
  };

  mapToViewModel(discount) {
    return {
      code: discount?.code || "",
    };
  }

  componentDidMount() {
    this.setState({
      data: this.mapToViewModel(this.props.appliedDiscount),
    });
  }

  handleRemoveDiscount = (event) => {
    event.preventDefault();

    this.props.onApplyDiscount(null, this.props.item.discountApplied);
    this.setState({ data: { code: "" } });
  };

  doSubmit = async () => {
    // check discount validity here, then send up to apply
    this.setState({ loading: true });
    const res = await getDiscountByCode(this.state.data.code);
    if (res.status === 200) {
      this.props.onApplyDiscount(res.data);
      toast.success("Discount applied");
    } else if (res.status === 429) {
      toast.dismissAll();
      toast.error("Please wait one minute to try again");
    } else toast.error(res.data);
    this.setState({ loading: false });
  };

  render() {
    const {
      renderInfoLine,
      userPaid,
      appliedDiscount,
      discountAmount,
      currency,
      item,
    } = this.props;

    const { loading } = this.state;

    if (userPaid && !appliedDiscount) return null;

    return (
      <div className="pop-box">
        {loading ? (
          <ComponentLoading />
        ) : (
          <div>
            {item.discountApplied &&
            !userPaid &&
            appliedDiscount &&
            appliedDiscount._id === item.discountApplied ? (
              <div className="text-center">
                <small>
                  A discount has been applied by your league admin. Enter a
                  different discount code to override.
                </small>
                <hr className="custom" />
              </div>
            ) : null}
            {!userPaid ? (
              <form onSubmit={this.handleSubmit}>
                {this.renderInput(
                  "code",
                  "Discount Code",
                  "",
                  "",
                  "",
                  "",
                  "",
                  null,
                  null,
                  null,
                  null,
                  null,
                  true,
                  (e) => {
                    e.target.value = e.target.value.toUpperCase();
                    return e;
                  }
                )}
                <div className="row">
                  <div className="col text-left">
                    {appliedDiscount &&
                    appliedDiscount?._id !== item.discountApplied ? (
                      <button
                        className="btn btn-dark btn-sm"
                        onClick={this.handleRemoveDiscount}
                      >
                        Remove
                      </button>
                    ) : null}
                  </div>
                  <div className="col text-right">
                    {this.renderValidatedButton("Apply")}
                  </div>
                </div>
              </form>
            ) : null}
            {appliedDiscount ? (
              <div>
                {renderInfoLine("Discount", `${appliedDiscount?.name}`)}
                {renderInfoLine(
                  "Discount Amount",
                  `${discountAmount?.toFixed(2)} ${currency.toUpperCase()}`
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default PlayerDiscountForm;
